:root {
	--nav-height: 56px;

	--font-small: .9rem;

}
#root {
	min-height: calc(var(--vh) * 100);
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
}
#root > main {
	flex: 1
}
#root * {
	/* transition: all .2s; */
}
body {
	transition: background-color .4s;
	overflow: overlay;
}

.reset-height {
	height: initial !important
}
.modal-content {
	color: initial !important;
	text-align: initial !important;
}
main {
	display: flex !important;
}
main > * {
	flex: 1;
}
a[disabled] {
	color: grey !important;
	cursor: no-drop;
}

a[href^="mailto:"]::before,
a[href].mailto-link::before {
	content: "📧 ";
}

/* :not(:root) is a trick to add more priority to this selector */
a:hover:not(:root),
a:focus:not(:root),
button:focus:not(:root),
img[tabindex]:focus:not(:root),
img[tabindex]:hover:not(:root) {
	outline: 2px auto orange !important;
	outline-offset: -2px;
}


.leaflet-tile {
	position: inherit !important;
}

/* I DON'T KNOW FOR WHERE IT IS, SHOULD WRAP THE COMPONENT
   WITH styled-component
.md-form>input[type]:not(.browser-default):not([type="search"]) {
	transition: margin .2s;
}

.md-form>input[type]:-webkit-autofill:not(.browser-default):not([type="search"]) {
	margin-top: 35px;
} */

/* styles for '...' */
.ellipsis-text {
	/* hide text if it more than N lines  */
	overflow: hidden;
	/* for set '...' in absolute position */
	position: relative;
	/* use this value to count block height */
	line-height: 1.2em;
	/* max-height = line-height (1.2) * lines max number (3) */
	max-height: 3.6em;
	/* fix problem when last visible word doesn't adjoin right side  */
	text-align: justify;
	/* place for '...' */
	/* margin-right: -1em; */
	padding-right: 1em;
}
.ellipsis-text::first-line {
	text-align: left;
}
.ellipsis-text.two-lines {
	max-height: 2.4em;
}

/* create the ... */
.ellipsis-text:before {
	/* points in the end */
	content: '...';
	/* absolute position */
	position: absolute;
	/* set position to right bottom corner of block */
	right: 0;
	bottom: 0;
}

/* hide ... if we have text, which is less than or equal to max lines */
.ellipsis-text:after {
	/* points in the end */
	content: '';
	/* absolute position */
	position: absolute;
	/* set position to right bottom corner of text */
	right: 0;
	/* set width and height */
	width: 1em;
	height: 1em;
	margin-top: 0.2em;
	/* bg color = bg color under block */
	background: white;
}

.go20 {
	margin: -20px !important;
	padding: 20px !important;
}
.navbar, body {
	font-weight: normal !important;
}

.navbar .navbar-brand a:hover,
.navbar .navbar-brand a:active,
.navbar .navbar-brand a:focus {
	padding: 5px;
	margin: -5px;
}
.navbar .navbar-brand a:hover,
.navbar .navbar-brand a:active,
.navbar .navbar-brand a:focus,
.navbar .navbar-toggler:hover,
.navbar .navbar-toggler:active,
.navbar .navbar-toggler:focus,
.navbar .nav-link:hover,
.navbar .nav-link:active,
.navbar .nav-link:focus {
	transition: background-color .4s;
	background-color: rgba(0,0,0,.1);
	border-radius: 3px;
}

.pagination .page-item.active .page-link {
	background-color: var(--PageMarkActive_bg_color) !important;
	color: var(--PageMarkActive_color) !important
}

.navbar .nav-item:last-child .nav-link {
	margin-right: -.5rem;
}

.spin {
	animation: spinning infinite 1s linear;
}
@keyframes spinning {
	from {
		transform: rotateZ(0deg);
	}
	to {
		transform: rotateZ(360deg);
	}
}

.form-control:disabled[type="checkbox"],
.form-control[readonly][type="checkbox"] {
	opacity: 0 !important;
}

.form-check-input[type=radio]:disabled:checked+label:before,
.form-check-input[type=radio]:disabled:not(:checked)+label:before,
label.btn input[type=radio]:disabled:checked+label:before,
label.btn input[type=radio]:disabled:not(:checked)+label:before {
	background-color: #bdbdbd !important;
	border-color: #bdbdbd !important;
}

.form-check-input[type=checkbox]:disabled:checked+label:before,
label.btn input[type=checkbox]:disabled:checked+label:before {
	border-right-color: #595959 !important;
	border-bottom-color: #595959 !important;
}

.form-check-input[type=radio].with-gap:disabled:checked+label:after,
.form-check-input[type=radio].with-gap:disabled:checked+label:before,
.form-check-input[type=radio]:disabled:checked+label:after,
label.btn input[type=radio].with-gap:disabled:checked+label:after,
label.btn input[type=radio].with-gap:disabled:checked+label:before,
label.btn input[type=radio]:disabled:checked+label:after {
	background-color: #595959 !important;
}

.form-check-input[type=radio].with-gap:disabled:checked+label:after,
.form-check-input[type=radio]:disabled:checked+label:after,
label.btn input[type=radio].with-gap:disabled:checked+label:after,
label.btn input[type=radio]:disabled:checked+label:after {
	border-color: #595959 !important
}

.form-control:disabled {
	background-color: rgba(0,0,0,.1) !important;
}

.form-control:read-only:not(.select-input):not(:disabled) {
	background-color: rgba(0, 0, 0, .05) !important;
}

.mdb-select .search-wrap.md-form input {
	padding: 0.6rem 1rem 0.4rem 1rem !important;
}

.Ripple[data-test="waves"] {
	pointer-events: none;
}

#consulta-desc img[style*="position: absolute"] {
	display: none;
}

.view img, .view video {
	position: absolute !important;
}
.card-footer {
	background-color: rgb(247 247 247) !important;
}
@media (max-width: 768px) {
	.card-footer {
		flex-direction: column-reverse;
	}

	.card-footer > * {
		justify-content: center !important;
	}
}

/* Apply .fa-fw to MDInput icons */
.md-form.md-outline .prefix,
.conpub-inputselect .fa {
	text-align: center;
	width: 1.25em;
}

blockquote {
	background: #f9f9f9;
	border-left: 10px solid #ccc;
	margin: 1.5em 10px;
	padding: 0.5em 10px;
	quotes: "\201C""\201D""\2018""\2019";
}

blockquote:after {
	color: #ccc;
	content: close-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
	text-align: right;
	float: right;
}

blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}
