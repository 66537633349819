:root {
	/* --Branding-Color: #3EC6FF; */
	/* --Branding-Color-Dark: #004F70; */

	--Branding-Grey-5: #E0E0E0;
	--Branding-Grey-6: #F2F2F2;
	--Branding-Red: #EB5757;
	--Branding-Text-White: #FFFFFF;

	--Spinner_normal: var(--secondaryTx);
	--Spinner_secondary: var(--secondaryBg);

	--Navbar_color: var(--primaryTx);
	--Navbar_bg_color: var(--primaryBg);
	--UpdateNavbar_color: var(--secondaryTxLight);
	--UpdateNavbar_bg_color: var(--secondaryBgLight);
	--Footer_color: var(--secondaryTxLight);
	--Footer_bg_color: var(--secondaryBgLight);

	--HomeMask_color: var(--secondaryTxDark);
	--HomeMask_bg_color: var(--secondaryBgDark);
	--HomeMask_svg: var(--primaryBgDark);

	--PageMain_color: var(--primaryTxLighten);
	--PageMain_bg_color: var(--primaryBgLighten);

	--CardHeader_color: var(--secondaryTxLight);
	--CardHeader_bg_color: var(--secondaryBgLight);
	--CardButton_color: var(--secondaryTx);
	--CardButton_bg_color: var(--secondaryBg);
	--CardButton_outline_color: var(--secondaryBgDark);

	--IconExpandFinalizada_color: var(--primaryBgDark);
	--IconExpandFinalizada_color_ACTIVE: var(--secondaryBgDark);
	--OpenDetailsFinalizada_color: var(--primaryBgDark);
	--OpenDetailsFinalizada_color_HOVER: var(--secondaryBgDark);
	--OpenResultsFinalizada_color: var(--primaryBgDark);
	--OpenResultsFinalizada_color_HOVER: var(--secondaryBgDark);
	--OpenPublicationFinalizada_color: var(--primaryBgDark);
	--OpenPublicationFinalizada_color_HOVER: var(--secondaryBgDark);

	--HeaderDetailsFinalizada_bg_color: var(--secondaryBg);
	--HeaderDetailsFinalizada_color: var(--secondaryTx);

	--PageMarkActive_color: var(--primaryTx);
	--PageMarkActive_bg_color: var(--primaryBg);

	--SVG_default_color: var(--primaryBg);

	--TableContriSendedHeader_color: var(--primaryTxDark);
	--TableContriSendedHeader_bg_color: var(--primaryBgDark);
	--TableContriSendedHeader_border_color: var(--primaryBg);
}
.coloredBg {
	background-color: var(--primaryBg);
	color: var(--primaryTx);
}